
<div fxLayoutAlign="center center" fxFlexFill>

  <form fxFlex="50" novalidate autocomplete="off"
    (ngSubmit)="onSubmit(form)"
    [formGroup]="form">
    <mat-card class="mat-elevation-z4">
      <mat-toolbar color="primary">
        <span fxFlex>Login</span>
        <mat-icon>security</mat-icon>
      </mat-toolbar>
      <mat-card-content fxLayout="column">
        <mat-form-field style="width:100%">
          <input matInput placeholder="Username" formControlName="username" autocapitalize="none">
          <mat-error *ngIf="form.controls.username.invalid">
            <span *ngIf="form.controls.username.hasError('required')">Username is <b>required.</b></span>
          </mat-error>
        </mat-form-field>
        <mat-form-field style="width:100%">
          <input matInput placeholder="Password" formControlName="password" type="password" autocomplete="new-password">
          <mat-error *ngIf="form.controls.password.invalid">
            <span *ngIf="form.controls.password.hasError('required')">Password is <b>required.</b></span>
          </mat-error>
        </mat-form-field>
        <mat-error *ngIf="form.invalid" style="font-size:12px">
          <div *ngIf="form.hasError('IncorrectCredentials')">
            <b>Incorrect</b> credentials
          </div>
          <div *ngIf="form.hasError('UserDisabled')">
            Account has been <b>disabled</b>
          </div>
          <div *ngIf="form.hasError('UserLockedOut')">
            Account has been <b>locked out</b> for exceeding the maximum number of attempts. Try again in <b>{{ form.getError('UserLockedOut') }}</b> minutes
          </div>
        </mat-error>
      </mat-card-content>
      <mat-card-actions fxLayoutAlign="end center">
        <button type="submit" mat-raised-button color="accent" [disabled]="form.invalid">Login</button>
      </mat-card-actions>
    </mat-card>
  </form>

</div>
