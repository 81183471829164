
import { AppComponent } from "app/app.component"
import { AppError } from "app/app.error"
import { AppInitializer } from "app/app.initializer"
import { AppService } from "app/app.service"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { BrowserModule } from "@angular/platform-browser"
import { CommonModule } from "@angular/common"
import { FlexLayoutModule } from "@angular/flex-layout"
import { FormsModule } from "@angular/forms"
import { HeartBeatService } from "app/heart-beat.service"
import { HttpClientModule } from "@angular/common/http"
import { HttpClientXsrfModule } from "@angular/common/http"
import { IsAuthenticatedGuard } from "app/auth.guard"
import { IsValidKioskActionGuard } from "app/kiosk.guard"
import { KioskComponent } from "app/kiosk.component"
import { LoginComponent } from "app/login.component"
import { MatModule } from "app/mat.module"
import { NgModule } from "@angular/core"
import { NotFoundComponent } from "app/not-found.component"
import { ReactiveFormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"
import { Routes } from "@angular/router"


const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "kiosk",
    component: KioskComponent,
    canActivate: [
      IsAuthenticatedGuard,
      IsValidKioskActionGuard,
    ],
  },
  {
    path: "",
    redirectTo: "/kiosk",
    pathMatch: "full"
  },
  {
    path: "**",
    component: NotFoundComponent
  }
]


@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FlexLayoutModule,
    MatModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({cookieName: "csrftoken", headerName: "X-CSRFToken"}),
    RouterModule.forRoot(routes, {enableTracing: false}),
  ],
  declarations: [
    AppComponent,
    KioskComponent,
    LoginComponent,
    NotFoundComponent,
  ],
  providers: [
    AppError,
    AppInitializer,
    AppService,
    HeartBeatService,
    IsAuthenticatedGuard,
    IsValidKioskActionGuard,
  ],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule { }
