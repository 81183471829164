
import { ActivatedRoute } from "@angular/router"
import { AppService } from "app/app.service"
import { Component } from "@angular/core"
import { DomSanitizer } from "@angular/platform-browser"
import { FormBuilder } from "@angular/forms"
import { FormGroup } from "@angular/forms"
import { Helo } from "app/rx-web-socket"
import { KioskAction } from "app/kiosk.enum"
import { Logging } from "app/rx-web-socket"
import { MatSnackBar } from "@angular/material"
import { OnDestroy } from "@angular/core"
import { OnInit } from "@angular/core"
import { ParamMap } from "@angular/router"
import { ResponseStatus } from "app/app.enum"
import { Router } from "@angular/router"
import { RxWebSocket } from "app/rx-web-socket"
import { Subject } from "rxjs"
import { takeUntil } from "rxjs/operators"
import { Validators } from "@angular/forms"


@Component({
  selector: "app-kiosk",
  templateUrl: "kiosk.component.html",
})
export class KioskComponent implements OnInit, OnDestroy {

  public action: string

  public forms: {[name: string]: FormGroup}

  public kiosk: any

  public KioskAction = KioskAction

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  public patient: any

  private rxWebSocket: RxWebSocket

  public user: any

  constructor(
    private appService: AppService,
    private domSanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
  ) { }

  buildForms() {
    this.forms = {}
    this.forms[KioskAction.kiosk_config] = this.formBuilder.group({
      cmd: [null],
      kiosk: this.formBuilder.group({
        name: [null, Validators.required],
      }),
      user: this.formBuilder.group({
        password: [null, Validators.required],
      }),
    })
    this.forms[KioskAction.patient_pin] = this.formBuilder.group({
      cmd: [null],
      patient: this.formBuilder.group({
        pin: [null, Validators.required],
      }),
    })
    this.forms[KioskAction.user_logout] = this.formBuilder.group({
      cmd: [null],
      user: this.formBuilder.group({
        password: [null, Validators.required],
      }),
    })
  }

  initForm(cmd) {
    if (this.forms.hasOwnProperty(cmd)) {
      let initial = {}
      // this is for debug only, we use this if available, but we never update this,
      // so if you plan to use this, you need to manually set it
      const password = this.appService.store("user.password")
      if (cmd === KioskAction.kiosk_config) {
        initial = {
          cmd: KioskAction.kiosk_config,
          kiosk: {
            name: this.appService.store("kiosk.name"),
          },
          user: {
            password: password,
          },
        }
      }
      else if (cmd === KioskAction.patient_pin) {
        initial = {
          cmd: KioskAction.patient_pin,
          patient: {
            pin: null
          },
        }
      }
      else if (cmd === KioskAction.user_logout) {
        initial = {
          cmd: KioskAction.user_logout,
          user: {
            password: password,
          },
        }
      }
      this.forms[cmd].reset(initial)
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
    window.removeEventListener("message", this.onWindowMessage, false)
    this.rxWebSocket.close()
  }

  ngOnInit() {
    const {host, protocol} = window.location
    this.buildForms()
    this.appService.user.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.user = user)
    this.appService.kiosk.pipe(takeUntil(this.ngUnsubscribe)).subscribe((kiosk) => this.kiosk = kiosk)
    this.appService.patient.pipe(takeUntil(this.ngUnsubscribe)).subscribe((patient) => {
      if (patient && patient.form && patient.form.url) {
        patient.form.url = this.domSanitizer.bypassSecurityTrustResourceUrl((`${protocol}//`).concat(patient.form.url))
      }
      this.patient = patient
    })
    this.route.paramMap.subscribe(
      (params: ParamMap) => {
        this.action = params.get("action")
        this.initForm(this.action)
      }
    )
    const helo: Helo = {
      doRequest: () => {
        return {
          stream: "kiosk",
          payload: {
            cmd: "helo",
            request: {
              target: "server",
              client: this.appService.client.getValue(),
            }
          }
        }
      },
      onResponse: (msg) => {
        const {stream, payload} = msg
        if (stream === "kiosk") {
          const {status, response} = payload
          if (status === ResponseStatus.STATUS_200_OK) {
            this.appService.client.next(response.client)
            this.appService.kiosk.next(response.kiosk)
            if (response.patient && response.patient.id !== this.appService.patient.getValue().id) {
              this.appService.patient.next(response.patient)
            }
            this.appService.heartBeat.beat()
          }
          else if (status === ResponseStatus.STATUS_407_AUTHENTICATION_REQUIRED) {
            if (this.router.routerState.snapshot.url !== "/login") {
              this.router.navigate(["/login"])
            }
          }
          else {
            console.group(`[RxWsSrv] RxWebSocket.helo.response [ERROR]`)
            console.log(msg)
            console.groupEnd()
          }
        }
      }
    }
    this.rxWebSocket = new RxWebSocket(`${protocol === "https:" ? "wss" : "ws"}://${host}/ws/`, helo, Logging.RxWsAPI)
    this.rxWebSocket.incomming.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (msg) => {
        console.group("[RxWsAPI] KioskComponent.ngOnInit.rxWebSocket.incomming.subscribe")
        console.log(msg)
        console.groupEnd()
        const {stream, payload} = msg
        if (stream === "kiosk") {
          const {cmd, status, request, response} = payload
          if (status === ResponseStatus.STATUS_200_OK) {
            if (cmd === KioskAction.kiosk_config) {
              this.appService.kiosk.next(response[cmd].kiosk)
              if (this.patient) {
                this.router.navigate(["kiosk", {action: "patient:pin"}])
              }
              else {
                this.router.navigate(["kiosk", {action: "kiosk:idle"}])
              }
            }
            else if (cmd === KioskAction.patient_pin) {
              this.appService.patient.next(response[cmd].patient)
            }
            else if (cmd === KioskAction.patient_done) {
              if (this.patient && this.patient.id === response[cmd].patient.id) {
                this.appService.patient.next(null)
                this.snackBar.open("Your information has been updated. Thank you!", undefined, {duration: 10000})
              }
            }
          }
          else if (status === ResponseStatus.STATUS_401_UNAUTHORIZED) {
            this.snackBar.open(`Not Authorized`, "Dismiss", {duration: 10000})
          }
          else if (status === ResponseStatus.STATUS_404_NOT_FOUND) {
            this.snackBar.open(`Not Found`, null, {duration: 2000})
          }
          else if (status === ResponseStatus.STATUS_407_AUTHENTICATION_REQUIRED) {
            this.router.navigate(["/login"])
          }
          else if (status === ResponseStatus.STATUS_412_PRECONDITION_FAILED) {
            this.setFormErrors(this.forms[cmd], response[cmd])
          }
          else if (status === undefined) {
            // those are direct messages from server that doesn't belongs to a client request, so no status
            if (cmd === KioskAction.patient_load) {
              this.appService.patient.next(request.patient)
            }
            else if (cmd === KioskAction.patient_discard) {
              this.appService.patient.next(null)
            }
            else if (cmd === KioskAction.user_logout) {
              this.router.navigate(["/login"])
              this.appService.user.next(null)
              this.snackBar.open("You are now logged out by server request. Have a nice day!", undefined, {
                duration: 10000
              })
            }
          }
        }
      },
      (error) => {
        console.log("kiosk stream error", error)
      },
      () => {
        console.log("completed")
      }
    )
    window.addEventListener("message", (event) => {
      this.onWindowMessage(event)
    }, false)
  }

  onSubmit(ngform) {
    const {cmd, ...data} = ngform.value
    if (cmd === KioskAction.user_logout) {
      this.appService.logout(ngform.value.user).subscribe(
        (_response) => {
          this.snackBar.open("You are now logged out. Have a nice day!", undefined, {duration: 10000})
          this.router.navigate(["/login"])
        },
        (response) => {
          const {status, error} = response
          if (status === ResponseStatus.STATUS_412_PRECONDITION_FAILED) {
            this.setFormErrors(this.forms[cmd], JSON.parse(error))
          }
        }
      )
    }
    else {
      const client = this.appService.client.getValue().id
      const target = "server"
      const request = {client, target}
      request[cmd] = data
      this.rxWebSocket.send({stream: "kiosk", payload: {cmd, request}}, false)
    }
  }

  onWindowMessage(event) {
    const {stream, payload} = event.data
    if (stream === "kiosk") {
      const {cmd, request} = payload
      if (cmd === KioskAction.patient_done) {
        request.client = this.appService.client.getValue().id
        request.target = "server"
        request[cmd].patient.id = this.patient.id
        this.rxWebSocket.send({stream: "kiosk", payload: {cmd, request}})
      }
    }
  }

  setFormErrors(form, error) {
    const {errors, ...group_errors} = error
    if (errors) {
      form.setErrors(errors)
    }
    Object.keys(group_errors).forEach((group) => {
      Object.keys(group_errors[group]).forEach((control) => {
        if (form.controls[group].controls.hasOwnProperty(control)) {
          form.controls[group].controls[control].setErrors(group_errors[group][control])
        }
      })
    })
  }

}
