
import { Component } from "@angular/core"
import { OnInit } from "@angular/core"


@Component({
  selector: "app-not-found",
  templateUrl: "not-found.component.html",
})
export class NotFoundComponent implements OnInit {

  public errorCode: number

  constructor() { }

  ngOnInit() {
    this.errorCode = 404
  }

}
