
<div fxLayoutAlign="center center" fxFlexFill>
  <mat-card fxFlex="50" class="mat-elevation-z4 text-align-center">
    <mat-card-title>
      <mat-icon class="mat-icon-4x">sentiment_dissatisfied</mat-icon>
    </mat-card-title>
    <mat-card-content [ngSwitch]="errorCode">
      <div *ngSwitchCase="404">
        <h1>Not found</h1>
        <h3>We couldn't find what you're looking for</h3>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="warn" routerLink="/">Go Home</button>
    </mat-card-actions>
  </mat-card>
</div>
