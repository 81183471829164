
export enum KioskAction {
  kiosk_config    = "kiosk:config",
  kiosk_idle      = "kiosk:idle",
  patient_discard = "patient:discard",
  patient_done    = "patient:done",
  patient_form    = "patient:form",
  patient_load    = "patient:load",
  patient_pin     = "patient:pin",
  user_logout     = "user:logout",
}
