
<div fxLayout="column" fxLayoutAlign="start stretch" fxFlexFill>
  <mat-toolbar color="primary" class="mat-elevation-z6">
    <div fxFlex="25" fxLayoutAlign="start">
      <a class="brand" routerLink="/">
        <img src="/assets/logo.png">
      </a>
    </div>
    <div fxFlex="50" fxLayoutAlign="center">
      <span>{{ user?.practice.name }}</span>
    </div>
    <div fxFlex="25" fxLayoutAlign="end">

      <button mat-icon-button [matMenuTriggerFor]="userMenu" *ngIf="user">
        <mat-icon>more_vert</mat-icon>
        <mat-menu #userMenu="matMenu">
          <span mat-menu-item disabled>
            <mat-icon>person</mat-icon>
            <span>{{ user.username }}</span>
          </span>
          <hr>
          <a mat-menu-item [routerLink]="['kiosk', {action: 'kiosk:config'}]">
            <mat-icon>my_location</mat-icon>
            <span>{{ kiosk?.name }}</span>
          </a>
          <a mat-menu-item [routerLink]="['kiosk', {action: 'user:logout'}]">
            <mat-icon>power_settings_new</mat-icon>
            <span>Sign Out</span>
          </a>
        </mat-menu>
      </button>
    </div>
  </mat-toolbar>
  <div fxFlex fxFlexFill class="app-container">
    <router-outlet></router-outlet>
  </div>
</div>
