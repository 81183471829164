
import "hammerjs"
import { AppModule } from "app/app.module"
import { enableProdMode } from "@angular/core"
import { environment } from "environments/environment"
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic"

console.group("[environment]")
console.log(environment)
console.groupEnd()

if (environment.production) {
  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err))
