
import { ActivatedRouteSnapshot } from "@angular/router"
import { AppService } from "app/app.service"
import { CanActivate } from "@angular/router"
import { CanActivateChild } from "@angular/router"
import { Injectable } from "@angular/core"
import { KioskAction } from "app/kiosk.enum"
import { Router } from "@angular/router"
import { RouterStateSnapshot } from "@angular/router"


@Injectable()
export class IsValidKioskActionGuard implements CanActivate, CanActivateChild {

  private kiosk: any

  private patient: any

  constructor(
    private appService: AppService,
    private router: Router,
  ) {
    this.appService.kiosk.subscribe((value) => {
      this.kiosk = value
    })
    this.appService.kiosk.subscribe((kiosk) => this.kiosk = kiosk)
    this.appService.kiosk.subscribe((patient) => this.patient = patient)
  }

  canActivate(next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    // console.group("[Guard] IsValidKioskActionGuard.canActivate")
    // console.log("kiosk", this.kiosk)
    let redirect: any[] = null
    const {action} = next.params
    if (next.params.action === undefined) {
      if (!this.kiosk) {
        redirect = ["kiosk", {action: "kiosk:config"}]
      }
      else if (!this.patient) {
        redirect = ["kiosk", {action: "kiosk:idle"}]
      }
      else {
        redirect = ["kiosk", {action: "patient:pin"}]
      }
    }
    else {
      if (action === KioskAction.kiosk_idle && !this.kiosk) {
        redirect = ["kiosk", {action: "kiosk:config"}]
      }
      else if (action === KioskAction.patient_pin && !this.patient) {
        redirect = ["kiosk", {action: "kiosk:idle"}]
      }
      else if (action === KioskAction.patient_form && !this.patient.url) {
        // redirect = ["kiosk", {action: "kiosk:pin"}]
      }
    }
    if (redirect) {
      // console.log("redirect to:", redirect)
      // console.groupEnd()
      this.router.navigate(redirect)
      return false
    }
    // console.log("continue to", state.url)
    // console.groupEnd()
    return true
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(next, state)
  }

}
