import * as tslib_1 from "tslib";
import { environment } from "environments/environment";
import { ErrorHandler } from "@angular/core";
import * as Raven from "raven-js";
import * as RavenPluginConsole from "raven-js/plugins/console";
var AppErrorHandler = /** @class */ (function (_super) {
    tslib_1.__extends(AppErrorHandler, _super);
    function AppErrorHandler() {
        var _this = _super.call(this) || this;
        if (environment.sentryConfig) {
            _this.raven = Raven.config(environment.sentryConfig.dsn, Object.assign(environment.sentryConfig.config, {
                serverName: window.location.hostname,
            }));
            _this.raven.addPlugin(RavenPluginConsole);
            _this.raven.install();
        }
        return _this;
    }
    AppErrorHandler.prototype.handleError = function (error) {
        _super.prototype.handleError.call(this, error);
        if (environment.sentryConfig) {
            Raven.captureException(error);
        }
    };
    return AppErrorHandler;
}(ErrorHandler));
export { AppErrorHandler };
export var AppError = {
    provide: ErrorHandler,
    useClass: AppErrorHandler,
};
