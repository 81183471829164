
import { environment } from "environments/environment"
import { ErrorHandler } from "@angular/core"
import * as Raven from "raven-js"
import * as RavenPluginConsole from "raven-js/plugins/console"


export class AppErrorHandler extends ErrorHandler {

  raven: Raven.RavenStatic

  constructor() {
    super()
    if (environment.sentryConfig) {
      this.raven = Raven.config(environment.sentryConfig.dsn, Object.assign(environment.sentryConfig.config, {
        serverName: window.location.hostname,
      }))
      this.raven.addPlugin(RavenPluginConsole)
      this.raven.install()
    }
  }

  handleError(error: any) {
    super.handleError(error)
    if (environment.sentryConfig) {
      Raven.captureException(error)
    }
  }
}


export const AppError = {
  provide: ErrorHandler,
  useClass: AppErrorHandler,
}
