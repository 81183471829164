
<div fxLayoutAlign="center center" fxFlexFill [ngSwitch]="action">

  <form fxFlex="50" novalidate autocomplete="off" *ngSwitchCase="KioskAction.kiosk_config"
    (ngSubmit)="onSubmit(forms[KioskAction.kiosk_config])"
    [formGroup]="forms[KioskAction.kiosk_config]">
    <mat-card  class="mat-elevation-z4">
      <mat-toolbar color="primary">
        <span fxFlex>Configure Kiosk</span>
        <mat-icon>my_location</mat-icon>
      </mat-toolbar>
      <mat-card-content fxLayout="column">
        <mat-form-field formGroupName="kiosk" style="width:100%">
          <input matInput formControlName="name" placeholder="Name" autocapitalize="none">
          <mat-error *ngIf="forms[KioskAction.kiosk_config].controls.kiosk.controls.name.invalid">
            <span *ngIf="forms[KioskAction.kiosk_config].controls.kiosk.controls.name.errors.required">
              Name is <b>required</b>
            </span>
            <span *ngIf="forms[KioskAction.kiosk_config].controls.kiosk.controls.name.errors.duplicated">
              Must be distinct to {{ forms[KioskAction.kiosk_config].controls.kiosk.controls.name.errors.duplicated }}
            </span>
          </mat-error>
        </mat-form-field>
        <mat-form-field formGroupName="user" style="width:100%">
          <input matInput formControlName="password" placeholder="Password" type="password">
          <mat-error *ngIf="forms[KioskAction.kiosk_config].controls.user.controls.password.invalid">
            <span *ngIf="forms[KioskAction.kiosk_config].controls.user.controls.password.errors.required">
              Password is <b>required</b>
            </span>
            <span *ngIf="forms[KioskAction.kiosk_config].controls.user.controls.password.errors.invalid">
              <b>Invalid</b> Password
            </span>
          </mat-error>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions fxLayoutAlign="end center">
        <button type="submit" mat-raised-button color="accent" [disabled]="forms[KioskAction.kiosk_config].invalid">Submit</button>
      </mat-card-actions>
    </mat-card>
  </form>

  <div fxFlex="50" *ngSwitchCase="KioskAction.kiosk_idle">
    <mat-card class="mat-elevation-z4">
      <img mat-card-image [src]="user.practice.picture || '/assets/picture.jpg'">
      <mat-card-subtitle>Idle</mat-card-subtitle>
      <mat-card-title>{{ kiosk.name }}</mat-card-title>
    </mat-card>
  </div>

  <form fxFlex="50" novalidate autocomplete="off" *ngSwitchCase="KioskAction.patient_pin"
    (ngSubmit)="onSubmit(forms[KioskAction.patient_pin])"
    [formGroup]="forms[KioskAction.patient_pin]">
    <mat-card class="mat-elevation-z4">
      <mat-toolbar color="primary">
        <span fxFlex>Enter PIN number</span>
        <mat-icon>vpn_key</mat-icon>
      </mat-toolbar>
      <mat-card-header>
        <div mat-card-avatar class="material-icons font-size-40px">account_circle</div>
        <mat-card-title>{{ patient.name }}</mat-card-title>
        <mat-card-subtitle>{{ patient.email }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content formGroupName="patient" fxLayout="column">
        <mat-form-field style="width:100%">
          <input type="number" matInput formControlName="pin" placeholder="Secret number to access the patient form">
          <mat-error *ngIf="forms[KioskAction.patient_pin].controls.patient.controls.pin.invalid">
            <span *ngIf="forms[KioskAction.patient_pin].controls.patient.controls.pin.hasError('required')">
              <b>Required</b> PIN
            </span>
            <span *ngIf="forms[KioskAction.patient_pin].controls.patient.controls.pin.hasError('invalid')">
              <b>Incorrect</b> PIN
            </span>
          </mat-error>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions fxLayoutAlign="end center">
        <button type="submit" mat-raised-button color="accent" [disabled]="forms[KioskAction.patient_pin].invalid">
          Submit
        </button>
      </mat-card-actions>
    </mat-card>
  </form>

  <div fxFlex="grow" fxFlexFill *ngSwitchCase="KioskAction.patient_form"
    class="mat-elevation-z4"
    style="background-color:white; overflow:auto; -webkit-overflow-scrolling:touch;">
    <iframe frameBorder="0" [src]="patient.form.url" style="width:100%;height:100%"></iframe>
  </div>

  <form fxFlex="50" novalidate autocomplete="off" *ngSwitchCase="KioskAction.user_logout"
    (ngSubmit)="onSubmit(forms[KioskAction.user_logout])"
    [formGroup]="forms[KioskAction.user_logout]">
    <mat-card class="mat-elevation-z4">
      <mat-toolbar color="primary">
        <span fxFlex>Are you sure?</span>
        <mat-icon>security</mat-icon>
      </mat-toolbar>
      <mat-card-content formGroupName="user">
        <mat-form-field style="width:100%">
          <input matInput formControlName="password" placeholder="Password" type="password">
          <mat-error *ngIf="forms[KioskAction.user_logout].controls.user.controls.password.invalid">
            <div *ngIf="forms[KioskAction.user_logout].controls.user.controls.password.hasError('required')">
              <b>Required</b> Password
            </div>
            <div *ngIf="forms[KioskAction.user_logout].controls.user.controls.password.hasError('invalid')">
              <b>Invalid</b> Password
            </div>
          </mat-error>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions fxLayoutAlign="end center">
        <button type="submit" mat-raised-button color="accent" [disabled]="forms[KioskAction.user_logout].invalid">
          Submit
        </button>
      </mat-card-actions>
    </mat-card>
  </form>

</div>
