
import { HeartBeat } from "app/heart-beat"
import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"


@Injectable()
export class HeartBeatService {

  constructor(private http: HttpClient, private router: Router) {}

  create(url: string, redirect: string, config) {
    return new HeartBeat(this.http, this.router, url, redirect, config)
  }

}
