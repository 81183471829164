
import { AppService } from "app/app.service"
import { Component } from "@angular/core"
import { FormBuilder } from "@angular/forms"
import { FormGroup } from "@angular/forms"
import { MatSnackBar } from "@angular/material"
import { OnInit } from "@angular/core"
import { ResponseStatus } from "app/app.enum"
import { Router } from "@angular/router"
import { Validators } from "@angular/forms"


@Component({
  selector: "app-login",
  templateUrl: "login.component.html"
})
export class LoginComponent implements OnInit {

  public form: FormGroup

  constructor(
    private appService: AppService,
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: [this.appService.store("user.username"), Validators.required],
      password: [this.appService.store("user.password") , Validators.required],
    })
  }

  onSubmit(form) {
    this.appService.login(form.value).subscribe(
      (_response) => {
        this.snackBar.open("You are now logged in. Have a nice day!", undefined, {duration: 4000})
        this.router.navigate(["/"])
      },
      (response) => {
        const {status, error} = response
        console.group("LoginComponent.onSubmit - appService.login()")
        console.log(response)
        console.groupEnd()
        if (status === ResponseStatus.STATUS_412_PRECONDITION_FAILED) {
          this.setFormErrors(this.form, error)
        }
      }
    )
  }

  setFormErrors(form, error) {
    const {errors, ...control_errors} = error
    if (errors) {
      form.setErrors(errors)
    }
    Object.keys(control_errors).forEach((control) => {
      if (form.controls.hasOwnProperty(control)) {
        form.controls[control].setErrors(error[control])
      }
    })
  }

}
