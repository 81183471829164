
import { BehaviorSubject } from "rxjs"
import { environment } from "environments/environment"
import { HeartBeat } from "app/heart-beat"
import { HeartBeatService } from "app/heart-beat.service"
import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { KioskAction } from "app/kiosk.enum"
import { mergeMap } from "rxjs/operators"
import { objectIsEmpty } from "app/utils"
import { Router } from "@angular/router"
import { skip } from "rxjs/operators"
import { tap } from "rxjs/operators"
import store from "store2"


@Injectable()
export class AppService {

  public client: BehaviorSubject<any>

  public heartBeat: HeartBeat

  public kiosk: BehaviorSubject<any>

  public patient: BehaviorSubject<any>

  public store: any = store

  public user: BehaviorSubject<any>

  constructor(
    private heartBeatService: HeartBeatService,
    private http: HttpClient,
    private router: Router,
  ) {
    this.client = new BehaviorSubject(this.store.namespace("client").getAll())
    this.client.pipe(skip(1)).subscribe((value) => {
      if (value) {
        this.store.namespace("client").setAll(value)
      }
      this.store.session.namespace("client").setAll(value)
    })
    this.kiosk = new BehaviorSubject(this.store.namespace("kiosk").getAll())
    this.kiosk.pipe(skip(1)).subscribe((value) => {
      if (value) {
        this.store.namespace("kiosk").setAll(value)
      }
      else {
        this.router.navigate(["kiosk", {action: KioskAction.kiosk_config}])
      }
      this.store.session.namespace("kiosk").setAll(value)
    })
    this.patient = new BehaviorSubject(this.store.namespace("patient").getAll())
    this.patient.pipe(skip(1)).subscribe((value) => {
      if (value && !objectIsEmpty(value)) {
        this.store.namespace("patient").setAll(value)
        this.store.session.namespace("patient").setAll(value)
        if (value.form && value.form.token) {
          this.router.navigate(["kiosk", {action: KioskAction.patient_form}])
        }
        else {
          this.router.navigate(["kiosk", {action: KioskAction.patient_pin}])
        }
      }
      else {
        this.store.namespace("patient").clear()
        this.store.session.namespace("patient").clear()
        this.router.navigate(["kiosk", {action: KioskAction.kiosk_idle}])
      }
    })
    this.user = new BehaviorSubject(undefined)
    this.user.pipe(skip(1)).subscribe((value) => {
      if (value) {
        this.heartBeat = this.heartBeatService.create("/api/heartbeat/", "/login", environment.heartbeatConfig)
        this.heartBeat.start()
        this.store.namespace("user").setAll(value)
      }
      else {
        if (this.heartBeat) {
          this.heartBeat.stop()
        }
      }
      this.store.session.namespace("user").setAll(value)
    })
  }

  authenticatedUser() {
    return this.http.get("/api/v2/~kiosk/kiosk-users/authenticated/").pipe(
      tap((user: any) => {
        this.user.next(user)
        console.group("AppService.authenticatedUser()")
        console.log(user)
        console.groupEnd()
      })
    )
  }

  login(credentials: any) {
    return this.logout(credentials).pipe(
      mergeMap(() => {
        return this.http.post("/api/v2/~kiosk/kiosk-users/login/", credentials).pipe(
          tap((user: any) => {
            this.user.next(user)
            console.group("AppService.login()")
            console.log(user)
            console.groupEnd()
          })
        )
      })
    )
  }

  logout(credentials: any) {
    return this.http.post("/api/v2/~kiosk/kiosk-users/logout/", credentials).pipe(
      tap(() => {
        this.user.next(null)
      })
    )
  }

}
