
import { ErrorObserver } from "rxjs"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"


export interface HeartBeatConfig {
  ping: number
  reconnections: number
}


export class HeartBeat {

  private heartBeatObserver: ErrorObserver<any>

  private intervalId: number = null

  private reconnectObserver: ErrorObserver<any>

  private retries = 0

  constructor(
    private http: HttpClient,
    private router: Router,
    private url: string,
    private redirect: string,
    private config: HeartBeatConfig,
  ) {
    this.heartBeatObserver = {
      error: () => {
        this.stop()
        this.reconnect()
      }
    }
    this.reconnectObserver = {
      next: () => {
        this.retries = 0
        this.start()
      },
      error: () => {
        this.reconnect()
      },
    }
  }

  beat() {
    if (this.retries === 0) {
      this.http.get(this.url).subscribe(this.heartBeatObserver)
    }
  }

  private reconnect() {
    this.retries += 1
    if (this.retries <= this.config.reconnections) {
      setTimeout(
        () => this.http.get(this.url).subscribe(this.reconnectObserver),
        this.retries * 1000
      )
    }
    else {
      this.retries = 0
      if (this.router.routerState.snapshot.url !== "/login") {
        this.router.navigate([this.redirect])
      }
    }
  }

  start() {
    if (this.intervalId === null) {
      this.intervalId = window.setInterval(
        () => this.http.get(this.url).subscribe(this.heartBeatObserver),
        this.config.ping * 1000
      )
    }
  }

  stop() {
    if (this.intervalId !== null) {
      window.clearInterval(this.intervalId)
      this.intervalId = null
    }
  }

}
