
import { MatAutocompleteModule } from "@angular/material"
import { MatButtonModule } from "@angular/material"
import { MatButtonToggleModule } from "@angular/material"
import { MatCardModule } from "@angular/material"
import { MatCheckboxModule } from "@angular/material"
import { MatChipsModule } from "@angular/material"
import { MatDatepickerModule } from "@angular/material"
import { MatDialogModule } from "@angular/material"
import { MatExpansionModule } from "@angular/material"
import { MatFormFieldModule } from "@angular/material"
import { MatGridListModule } from "@angular/material"
import { MatIconModule } from "@angular/material"
import { MatInputModule } from "@angular/material"
import { MatListModule } from "@angular/material"
import { MatMenuModule } from "@angular/material"
import { MatPaginatorModule } from "@angular/material"
import { MatProgressBarModule } from "@angular/material"
import { MatProgressSpinnerModule } from "@angular/material"
import { MatRadioModule } from "@angular/material"
import { MatSelectModule } from "@angular/material"
import { MatSidenavModule } from "@angular/material"
import { MatSlideToggleModule } from "@angular/material"
import { MatSliderModule } from "@angular/material"
import { MatSnackBarModule } from "@angular/material"
import { MatSortModule } from "@angular/material"
import { MatStepperModule } from "@angular/material"
import { MatTableModule } from "@angular/material"
import { MatTabsModule } from "@angular/material"
import { MatToolbarModule } from "@angular/material"
import { MatTooltipModule } from "@angular/material"
import { NgModule } from "@angular/core"


const MaterialModules = [
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSliderModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
]


@NgModule({
  imports: MaterialModules,
  exports: MaterialModules,
})
export class MatModule { }
