
import { Observable } from "rxjs"
import { Subject } from "rxjs"


export enum Logging {
  None    = 0,
  RxWsWs  = 1 << 1,
  RxWsRx  = 1 << 2,
  RxWsAPI = 1 << 3,
  RxWsSrv = 1 << 4,
  All     = ~(~0 << 31),
}


export class ResponseSubject<T> extends Subject<T> {}


export class RequestSubject<T> extends Subject<T> {}


export interface Helo {
  doRequest: Function
  onResponse: Function
}


export interface Request {
  payload?: any
  stream?: string
  text?: any
}


export interface Response {
  payload?: any
  stream?: string
  text?: any
}


export class RxWebSocket {

  protected curConnectAttempts: number

  public heloCompleted = false

  protected requestQueue: Request[]

  protected requestSubject: RequestSubject<Request>

  protected responseSubject: ResponseSubject<Response>

  protected socket: WebSocket

  constructor(
    private url: string,
    private helo: Helo,
    private logging: number= Logging.RxWsAPI,
    private maxConnectAttempts: number= 25,
  ) {
    this.curConnectAttempts = 0
    this.connect()
    this.requestQueue = []
    this.requestSubject = new RequestSubject()
    this.requestSubject.subscribe((msg) => {
      if (this.log(Logging.RxWsRx)) {
        console.group(`[${Logging[Logging.RxWsRx]}] RxWebSocket.requestSubject.observer.next (send|queue)`)
      }
      if (this.socket) {
        const encoded_msg = JSON.stringify(msg)
        if (this.socket.readyState === WebSocket.OPEN) {
          if (this.log(Logging.RxWsRx)) {
            console.log("sending:", encoded_msg)
          }
          this.socket.send(encoded_msg)
        }
        else {
          if (this.log(Logging.RxWsRx)) {
            console.log("queueing:", encoded_msg)
          }
          this.requestQueue.push(msg)
        }
      }
      else {
        this.connect()
      }
      if (this.log(Logging.RxWsRx)) {
        console.groupEnd()
      }
    })
    this.responseSubject = new ResponseSubject()
    this.responseSubject.subscribe((msg) => {
      if (this.log(Logging.RxWsRx)) {
        console.group(`[${Logging[Logging.RxWsRx]}] RxWebSocket.responseSubject.observer.next (log)`)
        console.log(msg)
        console.groupEnd()
      }
    })
  }

  close(): void {
    if (this.socket) {
      this.socket.close()
    }
  }

  private connect() {
    if (this.log(Logging.RxWsWs)) {
      console.group(`[${Logging[Logging.RxWsWs]}] RxWebSocket.connect (init and bind events)`)
      console.log(this.url)
      console.groupEnd()
    }
    const socket = new WebSocket(this.url)
    socket.onopen = this.onOpen.bind(this)
    socket.onmessage = this.onMessage.bind(this)
    socket.onerror = this.onError.bind(this)
    socket.onclose = this.onClose.bind(this)
    this.socket = socket
  }

  private log(level): boolean {
    return !!(this.logging & level)
  }

  private onClose(closeEvent: CloseEvent) {
    if (this.log(Logging.RxWsWs)) {
      console.group(`[${Logging[Logging.RxWsWs]}] RxWebSocket.onClose (trying to reconnect with backoff)`)
      console.log(closeEvent)
    }
    if (closeEvent.wasClean) {
      if (this.log(Logging.RxWsWs)) {
        console.log("Not retrying - clean close")
      }
      this.socket = null
      this.curConnectAttempts = 0
    }
    else {
      this.curConnectAttempts += 1
      if (this.log(Logging.RxWsWs)) {
        console.log(`Retrying - [retry:${this.curConnectAttempts}] [delay:${this.curConnectAttempts * 100}ms]`, )
      }
      if (this.curConnectAttempts < this.maxConnectAttempts) {
        window.setTimeout(() => {
          this.connect()
        }, this.curConnectAttempts * 100)
      }
    }
    if (this.log(Logging.RxWsWs)) {
      console.groupEnd()
    }
  }

  private onError(event: Event) {
    if (this.log(Logging.RxWsWs)) {
      console.group(`[${Logging[Logging.RxWsWs]}] RxWebSocket.onError (TODO)`)
      console.log(event)
      console.groupEnd()
    }
  }

  private onMessage(messageEvent: MessageEvent) {
    if (this.log(Logging.RxWsWs)) {
      console.group(`[${Logging[Logging.RxWsWs]}] RxWebSocket.onMessage (response.next)`)
      console.log(messageEvent.data)
      console.groupEnd()
    }
    const msg = JSON.parse(messageEvent.data)
    if (msg && msg.payload && msg.payload.cmd === "helo") {
      this.curConnectAttempts = 0
      this.helo.onResponse(msg)
      this.heloCompleted = true
    }
    else {
      this.responseSubject.next(msg)
    }
  }

  private onOpen(_event: Event) {
    this.heloCompleted = false
    const helo = this.helo.doRequest()
    if (this.log(Logging.RxWsRx)) {
      console.group(`[${Logging[Logging.RxWsRx]}] RxWebSocket.onOpen (requestSubject.next: helo and queue)`)
      console.log("helo: ", helo)
    }
    this.requestSubject.next(helo)
    if (this.requestQueue.length) {
      if (this.log(Logging.RxWsRx)) {
        console.log("requestQueue: ", this.requestQueue)
      }
      for (let i = this.requestQueue.length - 1; i >= 0; i--) {
        this.requestSubject.next(this.requestQueue.shift())
      }
    }
    if (this.log(Logging.RxWsRx)) {
      console.groupEnd()
    }
  }

  send(request: Request, log= true): void {
    if (log && this.log(Logging.RxWsAPI)) {
      console.group(`[${Logging[Logging.RxWsAPI]}] RxWebSocket.send (request.next)`)
      console.log(request)
      console.groupEnd()
    }
    this.requestSubject.next(request)
  }

  get incomming(): Observable<Response> {
    return this.responseSubject.asObservable()
  }

}
