import * as tslib_1 from "tslib";
import { Subject } from "rxjs";
export var Logging;
(function (Logging) {
    Logging[Logging["None"] = 0] = "None";
    Logging[Logging["RxWsWs"] = 2] = "RxWsWs";
    Logging[Logging["RxWsRx"] = 4] = "RxWsRx";
    Logging[Logging["RxWsAPI"] = 8] = "RxWsAPI";
    Logging[Logging["RxWsSrv"] = 16] = "RxWsSrv";
    Logging[Logging["All"] = 2147483647] = "All";
})(Logging || (Logging = {}));
var ResponseSubject = /** @class */ (function (_super) {
    tslib_1.__extends(ResponseSubject, _super);
    function ResponseSubject() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ResponseSubject;
}(Subject));
export { ResponseSubject };
var RequestSubject = /** @class */ (function (_super) {
    tslib_1.__extends(RequestSubject, _super);
    function RequestSubject() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RequestSubject;
}(Subject));
export { RequestSubject };
var RxWebSocket = /** @class */ (function () {
    function RxWebSocket(url, helo, logging, maxConnectAttempts) {
        if (logging === void 0) { logging = Logging.RxWsAPI; }
        if (maxConnectAttempts === void 0) { maxConnectAttempts = 25; }
        var _this = this;
        this.url = url;
        this.helo = helo;
        this.logging = logging;
        this.maxConnectAttempts = maxConnectAttempts;
        this.heloCompleted = false;
        this.curConnectAttempts = 0;
        this.connect();
        this.requestQueue = [];
        this.requestSubject = new RequestSubject();
        this.requestSubject.subscribe(function (msg) {
            if (_this.log(Logging.RxWsRx)) {
                console.group("[" + Logging[Logging.RxWsRx] + "] RxWebSocket.requestSubject.observer.next (send|queue)");
            }
            if (_this.socket) {
                var encoded_msg = JSON.stringify(msg);
                if (_this.socket.readyState === WebSocket.OPEN) {
                    if (_this.log(Logging.RxWsRx)) {
                        console.log("sending:", encoded_msg);
                    }
                    _this.socket.send(encoded_msg);
                }
                else {
                    if (_this.log(Logging.RxWsRx)) {
                        console.log("queueing:", encoded_msg);
                    }
                    _this.requestQueue.push(msg);
                }
            }
            else {
                _this.connect();
            }
            if (_this.log(Logging.RxWsRx)) {
                console.groupEnd();
            }
        });
        this.responseSubject = new ResponseSubject();
        this.responseSubject.subscribe(function (msg) {
            if (_this.log(Logging.RxWsRx)) {
                console.group("[" + Logging[Logging.RxWsRx] + "] RxWebSocket.responseSubject.observer.next (log)");
                console.log(msg);
                console.groupEnd();
            }
        });
    }
    RxWebSocket.prototype.close = function () {
        if (this.socket) {
            this.socket.close();
        }
    };
    RxWebSocket.prototype.connect = function () {
        if (this.log(Logging.RxWsWs)) {
            console.group("[" + Logging[Logging.RxWsWs] + "] RxWebSocket.connect (init and bind events)");
            console.log(this.url);
            console.groupEnd();
        }
        var socket = new WebSocket(this.url);
        socket.onopen = this.onOpen.bind(this);
        socket.onmessage = this.onMessage.bind(this);
        socket.onerror = this.onError.bind(this);
        socket.onclose = this.onClose.bind(this);
        this.socket = socket;
    };
    RxWebSocket.prototype.log = function (level) {
        return !!(this.logging & level);
    };
    RxWebSocket.prototype.onClose = function (closeEvent) {
        var _this = this;
        if (this.log(Logging.RxWsWs)) {
            console.group("[" + Logging[Logging.RxWsWs] + "] RxWebSocket.onClose (trying to reconnect with backoff)");
            console.log(closeEvent);
        }
        if (closeEvent.wasClean) {
            if (this.log(Logging.RxWsWs)) {
                console.log("Not retrying - clean close");
            }
            this.socket = null;
            this.curConnectAttempts = 0;
        }
        else {
            this.curConnectAttempts += 1;
            if (this.log(Logging.RxWsWs)) {
                console.log("Retrying - [retry:" + this.curConnectAttempts + "] [delay:" + this.curConnectAttempts * 100 + "ms]");
            }
            if (this.curConnectAttempts < this.maxConnectAttempts) {
                window.setTimeout(function () {
                    _this.connect();
                }, this.curConnectAttempts * 100);
            }
        }
        if (this.log(Logging.RxWsWs)) {
            console.groupEnd();
        }
    };
    RxWebSocket.prototype.onError = function (event) {
        if (this.log(Logging.RxWsWs)) {
            console.group("[" + Logging[Logging.RxWsWs] + "] RxWebSocket.onError (TODO)");
            console.log(event);
            console.groupEnd();
        }
    };
    RxWebSocket.prototype.onMessage = function (messageEvent) {
        if (this.log(Logging.RxWsWs)) {
            console.group("[" + Logging[Logging.RxWsWs] + "] RxWebSocket.onMessage (response.next)");
            console.log(messageEvent.data);
            console.groupEnd();
        }
        var msg = JSON.parse(messageEvent.data);
        if (msg && msg.payload && msg.payload.cmd === "helo") {
            this.curConnectAttempts = 0;
            this.helo.onResponse(msg);
            this.heloCompleted = true;
        }
        else {
            this.responseSubject.next(msg);
        }
    };
    RxWebSocket.prototype.onOpen = function (_event) {
        this.heloCompleted = false;
        var helo = this.helo.doRequest();
        if (this.log(Logging.RxWsRx)) {
            console.group("[" + Logging[Logging.RxWsRx] + "] RxWebSocket.onOpen (requestSubject.next: helo and queue)");
            console.log("helo: ", helo);
        }
        this.requestSubject.next(helo);
        if (this.requestQueue.length) {
            if (this.log(Logging.RxWsRx)) {
                console.log("requestQueue: ", this.requestQueue);
            }
            for (var i = this.requestQueue.length - 1; i >= 0; i--) {
                this.requestSubject.next(this.requestQueue.shift());
            }
        }
        if (this.log(Logging.RxWsRx)) {
            console.groupEnd();
        }
    };
    RxWebSocket.prototype.send = function (request, log) {
        if (log === void 0) { log = true; }
        if (log && this.log(Logging.RxWsAPI)) {
            console.group("[" + Logging[Logging.RxWsAPI] + "] RxWebSocket.send (request.next)");
            console.log(request);
            console.groupEnd();
        }
        this.requestSubject.next(request);
    };
    Object.defineProperty(RxWebSocket.prototype, "incomming", {
        get: function () {
            return this.responseSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    return RxWebSocket;
}());
export { RxWebSocket };
