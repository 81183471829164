
import { AppService } from "app/app.service"
import { Component } from "@angular/core"
import { OnInit } from "@angular/core"


@Component({
  selector: "app-root",
  styleUrls: ["app.component.scss"],
  templateUrl: "app.component.html",
})
export class AppComponent implements OnInit {

  public kiosk: any

  public user: any

  constructor(
    private appService: AppService,
  ) {}

  ngOnInit() {
    this.appService.user.subscribe((user) => this.user = user)
    this.appService.kiosk.subscribe((kiosk) => this.kiosk = kiosk)
  }
}
