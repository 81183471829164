
import { APP_INITIALIZER } from "@angular/core"
import { AppService } from "app/app.service"
import { Injector } from "@angular/core"


export function appInitializer(injector: Injector): Function {
  return () => {
    const appService = injector.get(AppService)
    appService.store.session.clear()
    return appService.authenticatedUser().toPromise()
  }
}


export const AppInitializer = {
  deps: [Injector],
  multi: true,
  provide: APP_INITIALIZER,
  useFactory: appInitializer,
}
